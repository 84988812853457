//development constants
/*  export const BASE_URL = "http://138.68.151.133/crm-admin/public/";
export const API_URL = "http://138.68.151.133/crm-admin/public/api/";
export const AVATAR_URL =
  "http://138.68.151.133/crm-admin/public/uploads/avatar/";
export const GROUP_URL =
  "http://138.68.151.133/crm-admin/public/uploads/group/";
export const SOCKET_URL = "http://138.68.151.133:2300/";
export const CONVERSATION_URL =
  "http://138.68.151.133/crm-admin/public/uploads/conversation/"; */

//live-constants
export const BASE_URL = "https://admin.acevedoteamapp.com/";
export const API_URL = "https://admin.acevedoteamapp.com/api/";
export const FOLLOWUPBOSS = "https://api.followupboss.com/v1/identity";
export const AVATAR_URL = "https://admin.acevedoteamapp.com/uploads/avatar/";
export const GROUP_URL = "https://admin.acevedoteamapp.com/uploads/group/";
export const SOCKET_URL = "https://chat.acevedoteamapp.com/";
export const CONVERSATION_URL =
  "https://admin.racknerddemo.com/uploads/conversation/";

//Chime-auth
export const CHIME_CLIENT_ID = "1juwtykvenw1s";
export const CLIENT_SECRET = "3a13d8bec081400c94e173e7be22b0b9";
export const CHIME_TOKEN = "b92a0278-8163-41ed-b36e-42d5e1eb3176";
