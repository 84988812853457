import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Layout, Menu, Col, Row, Modal, Input, Button, Form } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DashboardOutlined,
  MessageOutlined,
  FileMarkdownOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import UserDropdown from "./UserDropdown";
import Notification from "./Notification";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import Sidebar from "./Sidebar";

const AppLayout = () => {
  const { SubMenu } = Menu;
  const { Header, Sider } = Layout;
  const [collapsed, setCollapsed] = useState(false);

  const user = useSelector((state) => state.user);
  const { isAuth, userDetails = {} } = user;
  const chime_auth = localStorage.getItem("auth");

  const appID = "231996937471b3a9";
  const appRegion = "us";
  const authKey = "68ce4b361e55226f706e32765052dbfbea66c995";
  const widgetID = "3d481e1b-ceca-4327-af17-3199ded0f5af";


  useEffect(() => {
    if (userDetails?.user) {
      window.CometChatWidget.init({ appID, appRegion, authKey }).then((response) => {
        window.CometChatWidget.login({
          uid: userDetails?.user.id
        }).then((userLoginRes) => {
          window.CometChatWidget.launch({
            widgetID,
            docked: true,
            alignment: "right",
            roundedCorners: "true",
            height: "600px",
            width: "800px",
            // defaultID: "", //default UID (user) or GUID (group) to show,
            defaultType: 'user'
          })
        }, (userLoginError) => {
          console.log("userLoginError", userLoginError);
        })
      }, (error) => {
        console.log("error", error);
      });
    }
  }, [userDetails?.user]);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Layout>
        <div className="sidebar_nv">
          <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className="logo-nv">
              <h2> </h2>
            </div>
            <Menu mode="inline" /* defaultSelectedKeys={["1"]} */>
              <Menu.Item key="1" icon={<DashboardOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
              <SubMenu
                key="sub2"
                icon={<FileMarkdownOutlined />}
                title="My Agenda"
              >
                <Menu.Item key="5">
                  <Link to="/leads"> Leads </Link>
                </Menu.Item>

                {Boolean(chime_auth) ? (
                  <Menu.Item key="6">
                    <Link to="/calendardata"> Calendar</Link>
                  </Menu.Item>
                ) : null}

                {/* <Menu.Item key="7">
                  <Link to="/taskboard">Task Board</Link>
                </Menu.Item> */}
              </SubMenu>
              {/* <SubMenu
                key="sub3"
                icon={<VideoCameraOutlined />}
                title="Training"
              >
                <Menu.Item key="7">Videos</Menu.Item>

                <Menu.Item key="8">Documents</Menu.Item>
              </SubMenu> */}
              <Menu.Item key="8" icon={<VideoCameraOutlined />}>
                <Link to="/training-material">Training</Link>
              </Menu.Item>
              {/* <Menu.Item key="3" icon={<MessageOutlined />}>
                <Link to="/chat">Chat</Link>
              </Menu.Item> */}
              <Menu.Item key="4" icon={<UserOutlined />}>
                <Link to="/profile"> Profile</Link>
              </Menu.Item>
            </Menu>
          </Sider>
        </div>

        <Layout className="site-layout">
          <Header className="site-layout-background navbar_top">
            <Row
              gutter={[0, 0]}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="togglebtn0">
                  {React.createElement(
                    collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                    {
                      className: "trigger",
                      onClick: toggle,
                    }
                  )}

                  <div className="logouser">Agent panel</div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                {isAuth ? (
                  <div className="noti-userdrpdown">
                    {/* <Notification /> */}
                    <UserDropdown />
                  </div>
                ) : (
                  <div className="login-userdrpdown">
                    <Link to="/login">Login to get access</Link>
                  </div>
                )}
              </Col>
            </Row>
          </Header>
          <Outlet />
        </Layout>
      </Layout>
      {/* <div id="cometchat" style={{ position: "absolute", right: 10, bottom: 20, minHeight: 20, minWidth: 20, backgroundColor: "red" }} /> */}
    </div>
  );
};

export default AppLayout;
